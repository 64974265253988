<!-- layouts/theme.vue -->
<script lang="ts" setup></script>

<template>
  <main class="theme-app">
    <slot />
  </main>
  <AppFooter />
</template>

<style lang="scss">
//.theme-app {
  // &:deep {
  //   @import "~/pages/landing/mobile-app-1/assets/scss/theme.scss";
  // }
  
//}
@use '@/pages/landing/mobile-app-1/assets/scss/theme.scss' as *;

</style>
